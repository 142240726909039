/*
  If the number of TAB files has changed, the following variable is required.
  And it must be defined before `@import`.
*/
$tab-count: 3; // plus 1 for home tab

@import "jekyll-theme-chirpy";

/* append your custom style below */
.wave-hand {
  animation-name: wave-animation;
  animation-duration: 2s;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  transform-origin: 70% 70%;
  display: inline-block;

    @keyframes wave-animation {
      0% { transform: rotate(  0.0deg) }
      10% { transform: rotate(-10.0deg) }
      20% { transform: rotate( 12.0deg) }
      30% { transform: rotate(-10.0deg) }
      40% { transform: rotate(  9.0deg) }
      50% { transform: rotate(  0.0deg) }
      100% { transform: rotate(  0.0deg) }
    }
}

.post-tail-wrapper {
  margin-top: 3rem;
  border-bottom: 1px double var(--main-border-color);
  font-size: 0.85rem;
}

.reddit-button-container {
  display: flex;
  justify-content: center;
}

.reddit-link {
  text-decoration: none;
  color: white;
  border-radius: 8px;
  background-color: rgb(63, 63, 63);
  padding: 8px 16px;
  margin-top: 0;
  margin-bottom: 16px;
  margin-left: 0;
  margin-right: 0;
}

.reddit-link:hover {
  text-decoration: none;
  color: white;
  background-color: #535353;
}

.inline-icon-container {
  margin-right: 0.8em;
}

.inline-icon-container svg {
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
  fill: rgb(232, 230, 227);
}
